import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, logo, mobileHero, mobileLogo } from 'images'
import ViewMenuButton from './ViewMenuButton'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="115vh"
          maxHeight="750px"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          justifyEnd
          alignCenter
        >
          {/* <CFImage
            src={mobileLogo}
            w="100%"
            maxWidth="300px"
            alt="About"
            mt="20px"
          /> */}
          <CFView w="100%" column justifyStart alignCenter>
            <CFView pulsate textCenter pv="15px" w="80%">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) top / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center bg="rgba(0,0,0,0.7)" ph="50px" pv="5px">
            <CFImage src={heroText} w="100%" maxWidth="400px" alt="About" />
            <CFView row center mt="5px">
              <CFView ml="30px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
